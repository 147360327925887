<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center">
        <div
          class="w-100 h-100"
          :style="{
            background: `#f5e9da url('${background}')`,
            backgroundSize: 'cover',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
          }"
        ></div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Olá! Seja bem vindo. 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Faça login em sua conta com os seus dados de acesso
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="E-mail" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="identity"
                  vid="identity"
                  rules="required|min:3|email"
                >
                  <b-form-input
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="exemplo@gmail.com"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small class="text-info">Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      size="lg"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="remember"
                  name="remember"
                >
                  <span class="cursor-pointer">Lembrar-me</span>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay
                :show="busy"
                rounded="lg"
                opacity="0.6"
                @hidden="onHidden"
              >
                <template v-slot:overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="primary" />
                    <!-- We add an SR only text for screen readers -->
                    <span class="sr-only">Please wait...</span>
                  </div>
                </template>
                <b-button
                  ref="loginButton"
                  type="submit"
                  variant="info"
                  block
                  :disabled="invalid"
                  size="lg"
                >
                  Entrar
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <b-card-text v-if="enableRegister" class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div v-if="enableRegister" class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div
            v-if="enableRegister"
            class="auth-footer-btn d-flex justify-content-center"
          >
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Ripple from "vue-ripple-directive";

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BOverlay,
  BSpinner,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const bg_1 = require("@/assets/images/pages/login/bg/bg1.jpg");
const bg_2 = require("@/assets/images/pages/login/bg/bg2.jpg");
const bg_3 = require("@/assets/images/pages/login/bg/bg3.jpg");
const bg_4 = require("@/assets/images/pages/login/bg/bg4.jpg");
const bg_5 = require("@/assets/images/pages/login/bg/bg5.jpg");
const bg_6 = require("@/assets/images/pages/login/bg/bg6.jpg");
const bg_7 = require("@/assets/images/pages/login/bg/bg7.jpg");
const bg_8 = require("@/assets/images/pages/login/bg/bg8.jpg");

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BSpinner,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      remember: false,
      password: "",
      userEmail: "",
      random: Math.floor(Math.random() * 8) + 1,
      enableRegister: false,

      busy: false,

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    background() {
      let bg = bg_1;
      if (this.random === 1) bg = bg_1;
      if (this.random === 2) bg = bg_2;
      if (this.random === 3) bg = bg_3;
      if (this.random === 4) bg = bg_4;
      if (this.random === 5) bg = bg_5;
      if (this.random === 6) bg = bg_6;
      if (this.random === 7) bg = bg_7;
      if (this.random === 8) bg = bg_8;

      return bg;
    },
  },
  methods: {
    onHidden() {
      // Return focus to the button
      this.$refs.loginButton.focus();
    },

    async login() {
      this.busy = true;
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            const login = await useJwt.login({
              identity: this.userEmail,
              password: this.password,
              remember: this.remember,
            });

            this.busy = false;
            const { user, token } = login.data;
            useJwt.setToken(token);
            // useJwt.setRefreshToken(response.data.refreshToken);

            this.$store.commit("account/SET_ACCOUNT", user);
            this.$ability.update(user.ability);

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router
              .replace(
                getHomeRouteForLoggedInUser(
                  user.permission,
                  this.$router.currentRoute.query.to
                )
              )
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Bem vindo ${user.name}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Você logou com sucesso como ${user.permission}!`,
                  },
                });
              });
          } catch (error) {
            this.busy = false;
            // this.$refs.loginForm.setErrors(error.response.data.errors);
            if (error.response.data != undefined) {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: `Falha no login`,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: `${error.response.data.errors["identity"][0]}`,
                },
              });

              return;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
